import React, { useState } from 'react'
import Form from '../components/Form'
import Title from '../components/UI/Title'
import { FiGift } from 'react-icons/fi'
import Button from '../components/UI/Button'
import I18n from '../components/UI/I18n'
import Photo from '../components/UI/Photo'
import Info from '../components/UI/Info'
import Carrousel from '../components/UI/Carrousel'
import HelpCenter from '../components/HelpCenter'
if (typeof window !== `undefined`) import('smoothscroll-polyfill').then(e => e.polyfill())
const [instagram, facebook, youtube] = ['https://www.instagram.com/_smileybox/', 'https://www.facebook.com/smileybox.bornephoto', 'https://www.youtube.com/channel/UCrO4rwo8HASvO-HHJMgh6Cg']

export default function Home() {
  const [showHelp, setShowHelp] = useState()
  if (typeof window !== `undefined`) window.location.href = /^en\b/.test(navigator.language) ? 'https://live.wizito.com/wedding-photobooh-2' : 'https://www.wizito.com/photobooth-mariage'
  return null
}
